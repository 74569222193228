import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MainLayout, PostCard, PaginationHelper, PostsRow } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Layout, Row, Col, Divider, Typography, Tag } from 'antd'
const { Content } = Layout
const { Title, Text, Paragraph } = Typography
import { Parallax } from 'rc-scroll-anim'
import { Helmet } from 'react-helmet'
import Wave from "../images/icons/wave.svg";
/**
* Stories page
*
*
*/
const Stories = ({ data, location, pageContext }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const image = data.joeImage

    const page = {
        page: {
            title: `Stories`,
            meta_title: ``,
            meta_description: `A collection of AMP web stories made by Joe Czubiak  `,
            name: ``,
            feature_image: `images/social/stories.png`,
            description: `A collection of AMP web stories made by Joe Czubiak`,
        },
    }

    const stories = [
        {
            title: "Hi, I'm Joe",
            url: "https://amp.joeczubiak.com/web-stories/hi-im-joe-czubiak/",
            cover: "https://amp.joeczubiak.com/wp-content/uploads/2021/01/joe-czubiak-scaled.jpg",
        },
    ]

    const Hero = () => {
        return (
            <Content>
                <Row
                    className={`hero stories`}
                >
                    <Col xs={24} sm={24}>
                        <Title level={1}>Stories</Title>
                    </Col>
                    {/* <Col xs={24}>
                        <p>full-stack developer • marketer • designer</p>
                    </Col> */}
                </Row>
                <Row
                >
                    <Col xs={24} sm={24} style={{ overflow: `hidden` }}>
                        <Parallax
                            animation={{ x: `-10%`, playScale: [0, 1.3] }}
                            style={{ transform: `translateX(-40%)`, zIndex: 1, opacity: 1 }}
                            className=""
                        >
                            {/* <img className={`hero-wave`} src={`/images/wave.svg`} alt="" /> */}
                            <Wave className={`hero-wave`} />
                        </Parallax>
                    </Col>
                </Row>
            </Content>
        )
    }

    return (
        <>
            <Helmet>
                <script async src="https://cdn.ampproject.org/amp-story-player-v0.js"></script>
                <link href="https://cdn.ampproject.org/amp-story-player-v0.css" rel="stylesheet" type="text/css" />

            </Helmet>
            <MetaData location={location} data={page} />
            <MainLayout className={`stories`} hero={<Hero />} >
                <Row
                    gutter={[16, 24]}
                    justify={`center`}
                >
                    <Col
                        xs={22}
                        sm={22}
                        md={20}
                        lg={16}
                        xl={14}
                        xxl={12}
                    >
                        <Typography className="explaination">
                            <Paragraph className="title">
                                <strong>I'm experimenting with a new AMP web story format.</strong>
                            </Paragraph>
                            <Text>
                                They work like you might be familiar with in Instagram, Twitter and elsewhere. 
                                <br />
                                Tap right to go forward, tap left to go back. 
                            </Text>
                        </Typography>
                        
                    </Col>
                </Row>


                <Row
                    gutter={[16, 24]}
                    justify={`center`}
                >
                    { stories.map(story => (
                        <Col
                            xs={22}
                            sm={12}
                            md={8}
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="story"
                        >
                            <h2 style={{ textAlign: `center` }}>{ story.title }</h2>
                            <div class="ratio-3-5">
                                <amp-story-player>
                                    <a
                                        href={story.url}
                                    >
                                        { story.cover && 
                                            <img src={ story.cover } loading="lazy" width="100%" height="100%" amp-story-player-poster-img />
                                        }
                                        { story.title }
                                    </a>
                                </amp-story-player>
                            </div>
                        </Col>
                    
                    )) }
                </Row>


            </MainLayout>
        </>
    )
}

Stories.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.object,
        }),
        location: PropTypes.object,
        pageContext: PropTypes.object,
    }),
}

export default Stories

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
  }
 `
